import { httpService } from "../common/vue-plugin/httpService";
import { root } from "@/common/config";
export const shareLocation = (data) =>
  httpService(
    {
      url: `${root}/order/shareLocation`,
      method: "post",
      isNeedLoading: true,
      storeKey: "shareLocationLoading",
    },
    data,
  );
export const detail = (data) =>
  httpService(
    {
      url: `${root}/order/detail`,
      method: "get",
      isNeedLoading: true,
      storeKey: "detailLoading",
    },
    data,
  );
