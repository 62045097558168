<template>
  <div class="resultPage">
    <div :class="` top ${xs ? 'small' : ''} triangle`">
      <div class="text-center" v-if="!loading">
        <p :class="`${xs ? 'text-h5' : 'text-h3'} mb-5 font-weight-bold`">
          {{ $t("lang.share.title") }}
        </p>
        <p :class="`${xs ? 'text-h5' : 'text-h3'} font-weight-bold`">
          {{ $t("lang.share.subTitle") }}
        </p>
      </div>
      <div v-else>
        <div class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
      </div>
    </div>
    <div :class="` ${xs ? 'small' : 'card-box'} mt-10 pb-10`">
      <div v-if="result">
        <div :class="`result text-center text-subtitle-1 ${xs ? 'my-5 pl-4 pr-4' : 'my-7'}`">
          {{ result }}
        </div>
        <!-- <v-divider dark :class="`${xs ? 'my-5' : 'my-7'}`"></v-divider> -->
      </div>
      <div class="normal">
        <!-- <div
          :class="`mt-5 mb-5 text-center text-body-1 ${xs ? 'pl-4 pr-4' : ''}`"
          v-html="tipsHtml"
        ></div> -->
        <div :class="`products-try ${xs ? 'small' : ''}`">
          <div :class="`${xs ? 'text-h5' : 'text-h3'} font-weight-bold`">
            {{ $t("lang.share.refuse.attention.Discover") }}
          </div>
          <div :class="`${xs ? 'text-h5' : 'text-h3'} font-weight-bold mb-5`">
            {{ $t("lang.share.refuse.attention.CellTrack") }}
          </div>
          <div :class="`${xs ? 'text-h6' : 'text-h4'}`">
            {{ $t("lang.share.refuse.attention.productsTry") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: Boolean,
    result: String,
  },
  data() {
    return {};
  },
  mounted() {
    document.querySelector("html").scrollTo(0, 0);
  },
  computed: {
    tipsHtml() {
      return this.$t("lang.share.btn.tips", [
        `<a href='/' style='color: #6C5BE7'">${this.$t("lang.share.btn.linkHome")}</a>`,
      ]);
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>
<style lang="scss" scoped>
.normal {
  .products-try {
    &.small {
      width: 100%;
      height: 320px;
    }
    width: 70%;
    height: 380px;
    margin: 0 auto;
    background: #6c5be7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
  }
}
</style>
