<template>
  <div :class="`${xs ? 'share-small-location' : 'share-location'}`">
    <div v-if="!statusPage">
      <div :class="` top ${xs ? 'small' : ''} triangle`">
        <div class="text-center">
          <p :class="`${xs ? 'text-h5' : 'text-h3'} mb-5 font-weight-bold`">
            {{ $t("lang.share.title") }}
          </p>
          <p :class="`${xs ? 'text-h5' : 'text-h3'} font-weight-bold`">
            {{ $t("lang.share.subTitle") }}
          </p>
        </div>
      </div>
      <div :class="` ${xs ? 'small pl-4 pr-4' : 'card-box'} mt-10 pb-4`">
        <div class="text-body-1 word-content" v-html="$t('lang.share.dear')"></div>
        <div class="subtitle-2 mt-5 mb-3 font-weight-bold">
          {{ $t("lang.share.replySender") }}
        </div>
        <v-textarea
          filled
          rounded
          dense
          v-model="textareaVal"
          border-radius="10px"
          background-color="#F7F8F9"
        ></v-textarea>
        <div :class="`btnBox d-flex ${xs ? 'flex-column' : 'justify-space-around'}`">
          <v-btn
            color="#6AC251"
            depressed
            rounded
            dark
            large
            :min-width="xs ? '100%' : '30%'"
            :class="xs ? 'mb-4' : ''"
            :loading="shareLoading"
            class="text-transform-none"
            @click="handleShare"
          >
            <v-icon dark>mdi-check</v-icon>
            {{ $t("lang.share.btn.share") }}
          </v-btn>
          <v-btn
            color="#E74B4B"
            depressed
            rounded
            dark
            large
            @click="close"
            class="text-transform-none"
            :min-width="xs ? '100%' : '30%'"
          >
            <v-icon dark>mdi-close</v-icon>
            {{ $t("lang.share.btn.no") }}
          </v-btn>
        </div>
        <div class="tips mt-5 mb-5 text-center" v-html="tipsHtml"></div>
      </div>
    </div>
    <div v-else>
      <result-page :loading="loading" :result="!loading ? shareContent : ''"></result-page>
    </div>
  </div>
</template>
<script>
import { shareLocation, detail } from "@/service/share";
import { formRouned } from "@/common/const";
import resultPage from "@/components/resultPage";
import { mapLoad } from "@/common/vue-plugin/httpService";
import { mapMutations } from "vuex";
export default {
  components: { resultPage },
  data() {
    return {
      formRouned,
      statusPage: false,
      refuse: 0, // 0 是同意 1是拒绝；
      textareaVal: "",
      shareContent: "",
      shareLoading: false,
    };
  },
  created() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
  computed: {
    ...mapLoad(["shareLocationLoading", "detailLoading"]),
    tipsHtml() {
      return this.$t("lang.share.btn.tips", [
        `<a href='/' style='color: #6C5BE7'">${this.$t("lang.share.btn.linkHome")}</a>`,
      ]);
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    loading() {
      return this.shareLocationLoading || this.detailLoading;
    },
  },
  methods: {
    ...mapMutations(["mergeState"]),
    async init() {
      this.textareaVal = "";
      const serialId = this.$route.params.id;
      const { data, code } = await detail({ serialId, language: this.$getLang() });
      this.trackOrderId = Number(code) ? "" : data.id;
    },
    async sendStaus(params) {
      if (!this.trackOrderId) {
        this.mergeState({
          snackbar: { show: true, msg: this.$t("lang.share.requiredTrackOrderId"), type: "error" },
        });
        return;
      }
      this.statusPage = true;
      const message = this.textareaVal;
      const { shareCode } = await shareLocation({
        message,
        trackOrderId: this.trackOrderId,
        ...params,
      });
      if (Number(shareCode)) this.statusPage = false;
    },
    handleShare() {
      const that = this;
      this.shareContent = this.$t("lang.share.allow.result");
      this.shareLoading = true;
      navigator.geolocation.getCurrentPosition(
        function (pos) {
          that.shareLoading = false;
          that.sendStaus({
            isRefuse: 0,
            location: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
              language: that.$getLang(),
            },
          });
        },
        function (error) {
          that.shareLoading = false;
          that.close();
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 36000,
        },
      );
    },
    close() {
      this.shareContent = this.$t("lang.share.refuse.result");
      this.sendStaus({ isRefuse: 1, location: {} });
    },
  },
};
</script>
<style lang="scss" scoped>
.share-location {
  background: #f7f7f7;
  min-height: 100%;
}
.share-small-location {
  background: #fff;
  min-height: 100%;
}
.word-content {
  word-break: break-word;
}
</style>
